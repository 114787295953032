const mySwiper = new Swiper('.top-slider-hero', {
  // Optional parameters
  loop: true,
  speed: 600,
  spaceBetween: 30,
  centeredSlides: true,
  effect: 'fade',
  autoplay: {
    delay: 2500,
    stopOnLast: false,
    disableOnInteraction: false,
  },
});

const carouselSwiper = new Swiper('.banner-carousel-wrapper', {
  // Optional parameters
  loop: false,
  speed: 600,
  slidesPerView: 2,
  spaceBetween: 10,
  //centeredSlides: true,
  //effect: 'fade',
  //autoplay: {
  //  delay: 2500,
  //  stopOnLast: false,
  //  disableOnInteraction: false,
  //},
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 30,
    }
  }

});
